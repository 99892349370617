<template>
  <div class="fixed bottom-20 right-6 flex flex-col items-center justify-center space-y-2 z-40">
  <a href="https://wa.me/XXXXXXXXXX" target="_blank" rel="noopener noreferrer" class="relative flex items-center justify-center w-14 h-14 bg-green-500 rounded-full shadow-lg hover:bg-green-600
      transition-colors text-white text-3xl">
    <span class="absolute w-full h-full rounded-full bg-green-400 opacity-60"></span>
    <font-awesome-icon class="relative z-40" :icon="['fab', 'whatsapp']"></font-awesome-icon>
  </a>
  <span class="text-xs text-center">Falar com Amanda</span>
</div>

</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faWhatsapp)

export default {
  name: 'FloatingWhatsButton',
  components: {
    FontAwesomeIcon
  }
}
</script>